/* ./src/index.css */

@tailwind base;
@tailwind components;
@layer utilities {
  .thepage {
    display: grid;
    height: 100vh;
    grid-template-rows: auto 1fr auto;
  }

  header {
  }

  main {
  }

  footer {
  }

  .bg-body {
    background: rgb(8, 2, 105);
    background: radial-gradient(
      circle,
      rgba(8, 2, 105, 1) 25%,
      rgba(0, 0, 0, 1) 100%
    );
  }
  .field-wrap {
    @apply border-2 border-gray-800 p-4 rounded-lg h-24 overflow-hidden;
    background-color: #111827;
  }
  .nav a {
    @apply inline-block mr-4 text-lg text-gray-300 hover:text-blue-500 font-semibold;
  }
  .nav a.selected {
    @apply text-blue-500;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply font-black !important;
  }
  li[aria-disabled='true'],
  li[aria-disabled='true'] > div {
    @apply pointer-events-none cursor-not-allowed select-none opacity-50 !important;
  }
  .tag_d {
    @apply px-2 py-1 text-sm rounded-full text-blue-300 border border-blue-300 tracking-wider hover:bg-blue-300 hover:text-gray-800 cursor-pointer font-bold;
  }
  .tag_d.active {
    @apply bg-blue-300 text-gray-800;
  }
}
@tailwind utilities;
